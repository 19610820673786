import React, { Component } from 'react';
import Navbar from '../components/navbar';
import ImageSlider from '../components/ImageSlider';
import AllProducts from '../components/AllProductHome';
import AllCategory from "../components/AllCategoryHome";
import Allcate from "../components/AllCateHome";
import AllBanner from "../components/AllBanner";
 import './home.css';

class Index extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <div  class="body">
        <div class="homebody">
        <div className="row bannerbox">
  <div className="col-md-2 categorysidehomeMain">
    <Allcate />
  </div>
  <div className="col-md-7">
    <ImageSlider />
  </div>
  <div className='col-md-3'>
  <AllBanner />
  </div>
</div>
<div className="container1 mt-4">
  <h2 className="mb-4">All Categories</h2>
  <AllCategory />
</div>



<div className="container mt-4">
  <h2 className="mb-4">Products</h2>
  <AllProducts />
</div>
</div>


</div>
      </div>
    );
  }
}

export default Index;
