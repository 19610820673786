import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './navbar.css'; // Import your CSS file for additional styling

import logo from '../icons/logo.png'; // Adjust path if needed
import shop from '../icons/cart.png'; // Adjust path if needed
import sell from '../icons/store.png'; // Adjust path if needed
import help from '../icons/help.png'; // Adjust path if needed
import user from '../icons/avatar.png'; // Adjust path if needed

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="container1">
        <div className="row">
          <div className="col-sm-2">
            <Link to="/" className="navbar-brand">
              <img
                src={logo}
                alt="Logo"
                className="navbar-logo"
              />
            </Link>
          </div>
          <div className="col-sm-5">
            <form className="d-flex mx-auto my-2 mt-2">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button
                className="btn searchheader"
                type="submit"
                aria-label="Search"
              >
                <i className="fas fa-search"></i>
              </button>
            </form>
          </div>
          <div className="col-sm-4">
            <ul className=" menulist">
              <li className="nav-item">
               
                <Link to="/" className="nav-link">
                <img
                src={shop}
                alt="Logo"
                 className="iconsheader"
              />
                Shopping
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/Vendor/Signup" className="nav-link">
                <img
                src={sell}
                alt="Logo"
               className="iconsheader"
              />
                 Sell
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/vendors" className="nav-link">
                <img
                src={help}
                alt="Logo"
                className="iconsheader"
              />
                  Help
                </Link>
              </li>
              <li className="nav-item">
              <img
                src={user}
                alt="Logo"
                className="iconsheader"
              />
                <Link to="/signup" className="nav-link">
                 Login/Signup
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
