import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AllCategory = () => {
  const [category, setCategory] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.get('http://localhost:5000/getsubCategoryHome');
        const data = response.data;

        if (data.status === 'ok') {
          setCategory(data.data); // Set data to 'category' state
        } else {
          setMessage(data.message);
        }
      } catch (error) {
        setMessage('An error occurred: ' + error.message);
      }
    };

    fetchCategory();
  }, []);

  return (
    <div className="container1">
      {message && <p>{message}</p>}
      {error && <p>{error}</p>}
      <div className="row">
        {category.map((cat, index) => (
          <div key={index} className="col-md-2 homecategoryside
           mb-4">
            <div className="card  categorycard h-100">
              {cat.image ? (
                 <img 
                 src={cat.image ? `http://localhost:5000/uploads/${cat.image.replace('\\', '/')}` : 'path_to_default_image.jpg'} 
                 className="card-img-top categoryimage"  
                 alt={cat.name || 'default'}
               />
              ) : (
                <img 
                  src="path_to_default_image.jpg" 
                  className="card-img-top" 
                  alt="default"
                />
              )}
              <div className="card-body">
                <h5 className="card-title categoryname">{cat.name}</h5>
                {/* Add more category details or buttons here */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllCategory;
